// SOL MENU

import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';

// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name, isIconify) =>
//   !isIconify ? (
//     <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   ) : (
//     <Iconify icon={name} />
//   );
// https://icon-sets.iconify.design/solar/
// https://www.streamlinehq.com/icons

const ICONS = {
  dashboard: <Iconify icon="solar:home-2-bold" />,
  project: <Iconify icon="solar:folder-with-files-bold" />,
  projectList: <Iconify icon="solar:folder-with-files-bold" />,
  projectNew: <Iconify icon="solar:add-folder-bold" />,
  workorder: <Iconify icon="solar:clipboard-list-bold" />,
  tasks: <Iconify icon="solar:task-list-bold" />,
  catalog: <Iconify icon="solar:box-minimalistic-bold" />,
  catalogList: <Iconify icon="solar:box-minimalistic-bold" />,
  catalogNew: <Iconify icon="solar:add-square-bold" />,
  order: <Iconify icon="solar:cart-large-bold" />,
  orderList: <Iconify icon="solar:cart-large-bold" />,
  orderNew: <Iconify icon="solar:cart-plus-bold" />,
  user: <Iconify icon="solar:users-group-rounded-bold" />,
  userList: <Iconify icon="solar:users-group-rounded-bold" />,
  userNew: <Iconify icon="solar:user-plus-rounded-bold" />,
  customer: <Iconify icon="solar:user-id-bold" />,
  customerList: <Iconify icon="solar:users-group-rounded-bold" />,
  customerNew: <Iconify icon="solar:user-plus-bold" />
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // Overview
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard
          }
        ]
      },
      // Production
      {
        subheader: t('production'),
        items: [
          {
            title: t('project-management'),
            path: paths.production.projectManagement.root,
            icon: ICONS.project,
            children: [
              {
                title: t('project-list'),
                path: paths.production.projectManagement.root,
                icon: ICONS.projectList
              },
              {
                title: t('project-new'),
                path: paths.production.projectManagement.create,
                icon: ICONS.projectNew
              }
            ]
          },
          // {
          //   title: 'Üretim İşleri',
          //   path: paths.production.projectManagement.root,
          //   icon: ICONS.booking,
          // },
          // {
          //  title: 'İş Emirleri',
          //  path: paths.workOrder.root,
          //  icon: ICONS.workorder
          // },
          {
            title: 'Görevler',
            path: paths.taskQueue.root,
            icon: ICONS.workorder
          }
        ]
      },
      // Catalog
      {
        subheader: t('catalog'),
        items: [
          {
            title: t('catalog-management'),
            path: paths.catalog.catalogManagement.root,
            icon: ICONS.catalog,
            children: [
              {
                title: t('catalog-list'),
                path: paths.catalog.catalogManagement.root,
                icon: ICONS.catalogList
              },
              {
                title: t('catalog-new'),
                path: paths.catalog.catalogManagement.create,
                icon: ICONS.catalogNew
              }
            ]
          }
        ]
      },
      // Management
      {
        subheader: 'Satış',
        items: [
          {
            title: t('order-management'),
            path: paths.order.root,
            icon: ICONS.order,
            children: [
              { title: t('order-list'), path: paths.order.root, icon: ICONS.orderList },
              {
                title: t('order-create'),
                path: paths.order.new,
                icon: ICONS.orderNew
              }
            ]
          }
        ]
      },
      {
        subheader: t('management'),
        items: [
          {
            title: t('user-management'),
            path: paths.user.root,
            icon: ICONS.user,
            children: [
              {
                title: t('user-list'),
                path: paths.user.list,
                icon: ICONS.userList
              },
              {
                title: t('user-new'),
                path: paths.user.new,
                icon: ICONS.userNew
              }
            ]
          },
          {
            title: t('customer-management'),
            path: paths.customer.root,
            icon: ICONS.customer,
            children: [
              {
                title: t('customer-list'),
                path: paths.customer.customerManagement.root,
                icon: ICONS.customerList
              },
              {
                title: t('customer-new'),
                path: paths.customer.customerManagement.create,
                icon: ICONS.customerNew
              }
            ]
          }
        ]
      }
    ],
    [t]
  );

  return data;
}
