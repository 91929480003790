import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from 'src/layouts/main';
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { appRoutes } from './app';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      )
    },
    ...authRoutes,
    ...appRoutes,
    ...dashboardRoutes,
    ...mainRoutes,
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
